/* frontend/src/styles/Navigation.css */
.navigation {
  background: white;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 0.75rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3182ce;
  text-decoration: none;
  background: linear-gradient(90deg, #3182ce 0%, #4299e1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #4a5568;
  text-decoration: none;
  font-weight: 500;
  padding: 0.4rem 0.6rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  color: #3182ce;
  background-color: rgba(49, 130, 206, 0.08);
}

.logout-btn {
  background: #f8f9fa;
  color: #e53e3e;
  border: 1px solid #e2e8f0;
  padding: 0.4rem 0.75rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-btn:hover {
  background-color: #fed7d7;
  color: #c53030;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 992px) {
  .nav-container {
    padding: 0 1rem;
  }
  
  .menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a5568;
  }
  
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
    padding: 5rem 1.5rem;
    transition: all 0.3s ease;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    gap: 1rem;
    z-index: 1001;
  }
  
  .nav-links.active {
    right: 0;
  }
  
  .nav-links li {
    width: 100%;
  }
  
  .nav-links li a, .logout-btn {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.75rem 0.5rem;
  }
}

@media (max-width: 576px) {
  .logo {
    font-size: 1.25rem;
  }
  
  .nav-links {
    width: 85%;
    padding: 4rem 1rem;
  }
}

/* //////////////////////////////////////////// better design looks above / */
/* .navigation {
    background-color: #2c3e50;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin-left: 1.5rem;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .nav-links a:hover {
    color: #3498db;
  }
  
  .logout-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s;
    padding: 0;
  }
  
  .logout-btn:hover {
    color: #3498db;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
    color: white;
  }
  
  @media (max-width: 768px) {
    .menu-icon {
      display: block;
    }
  
    .nav-links {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #2c3e50;
      flex-direction: column;
      align-items: center;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease;
      z-index: 1000;
    }
  
    .nav-links.active {
      height: calc(100vh - 60px);
      padding: 1rem 0;
    }
  
    .nav-links li {
      margin: 1rem 0;
    }
  } */