/* frontend/src/styles/Home.css */
.home-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Hero Section - Constrained to fit in viewport */
.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 2rem;
  flex: 1;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.hero-content {
  padding: 2rem;
  max-width: 600px;
}

.hero-content h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #2d3748 0%, #4a5568 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-content p {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 2rem;
  font-weight: 400;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
}

.btn-primary, .btn-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.btn-primary {
  background: linear-gradient(135deg, #4299e1 0%, #3182ce 100%);
  color: white;
  box-shadow: 0 4px 6px rgba(66, 153, 225, 0.3);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(66, 153, 225, 0.4);
}

.btn-secondary {
  background: transparent;
  color: #3182ce;
  border: 2px solid #3182ce;
}

.btn-secondary:hover {
  background-color: rgba(49, 130, 206, 0.1);
  transform: translateY(-2px);
}

.hero-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  transition: transform 0.5s ease;
}

.hero-image-wrapper:hover .hero-image {
  transform: scale(1.03);
}

/* Feature Cards - Made more compact */
.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 2rem;
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -4rem;
  position: relative;
  z-index: 3;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.feature-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
  border: 1px solid #e2e8f0;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #e6f7ff 0%, #bae7ff 100%);
}

.feature-card h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: #2d3748;
}

.feature-card p {
  color: #4a5568;
  line-height: 1.5;
  font-size: 0.95rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .hero {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 1.5rem;
  }
  
  .hero-content {
    margin: 0 auto;
    order: 1;
    padding: 1.5rem 1rem;
  }
  
  .hero-image-container {
    margin-bottom: 1.5rem;
    order: 0;
  }
  
  .cta-buttons {
    justify-content: center;
  }
  
  .feature-cards {
    margin-top: 1rem;
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero {
    padding: 1rem 0.5rem;
  }
  
  .hero-content {
    padding: 1rem 0.5rem;
  }
  
  .cta-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    gap: 0.75rem;
  }
  
  .btn-primary, .btn-secondary {
    width: 100%;
    padding: 0.6rem 1.25rem;
  }
  
  .feature-cards {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }
  
  .feature-card {
    padding: 1.25rem;
  }
  
  .feature-icon {
    width: 50px;
    height: 50px;
    font-size: 1.75rem;
  }
}
/* frontend/src/styles/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: #2d3748;
  background-color: #f8f9fa;
  overflow-x: hidden;
}

/* Main content area - ensure it can fit on one page */
.main-content {
  min-height: calc(100vh - 120px); /* Adjusted for smaller footer */
  display: flex;
  flex-direction: column;
}

/* Common container for all pages */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  .page-container {
    padding: 0.75rem;
  }
}