/* frontend/src/styles/Footer.css */
.footer {
  background-color: #f8f9fa;
  color: #4a5568;
  padding: 1.5rem 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-top: 1px solid #e2e8f0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
}

.footer-links a {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 500;
}

.footer-links a:hover {
  color: #3182ce;
}

.footer-copyright {
  font-size: 0.9rem;
  color: #718096;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 0 1rem;
  }
  
  .footer-links {
    margin-bottom: 0.5rem;
  }
}

/* 
Better design look above 

.footer {
    background-color: #2c3e50;
    color: white;
    padding: 1.5rem 0;
    margin-top: 2rem;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  
  .footer-links {
    display: flex;
    gap: 1.5rem;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #3498db;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-links {
      margin-top: 1rem;
      gap: 1rem;
    }
  } */